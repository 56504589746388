import { actionOn, thunk, action } from 'easy-peasy';
import capitalize from 'lodash/capitalize';

export default (actionName, thunkAction) => {
  const sendName = `send${capitalize(actionName)}`;

  return {
    [sendName]: thunk(thunkAction),
    [`onSend${capitalize(actionName)}Success`]: actionOn(
      actions => actions[sendName].successType,
      (state, { result }) => {
        state[actionName] = result;
        state.submitted = true;
        state.isSubmitting = false;
        state.submitError = undefined;
      }
    ),
    [`onSend${capitalize(actionName)}Start`]: actionOn(
      actions => actions[sendName].startType,
      state => {
        state.isSubmitting = true;
      }
    ),
    [`onSend${capitalize(actionName)}Error`]: actionOn(
      actions => actions[sendName].failType,
      (state, { error }) => {
        state.submitted = false;
        state.isSubmitting = false;
        state.submitError = error.data;
      }
    ),
    [`set${capitalize(actionName)}`]: action((state, payload) => {
      state[actionName] = payload;
      state.isSubmitting = false;
      state.submitted = true;
      state.submitError = undefined;
    }),
  };
};
