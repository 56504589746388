import React, { Fragment, useMemo } from 'react';
import { Menu, Divider } from '@material-ui/core';
import {
  PowerOff as PowerOffIcon,
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { bindMenu } from 'material-ui-popup-state/hooks';
import { Trans } from 'react-i18next';
import authStore from '@vdx-platform/core/auth/store';
import OptionItem from './OptionItem';
import { usePopupState } from '../context/popup';
import SubOptions from './SubOptions';

const Options = () => {
  const popupState = usePopupState();

  const logout = authStore.useStoreActions(actions => actions.logout);

  const items = useMemo(
    () => [
      {
        key: 'settings',
        onClick: popupState.close,
        AnchorProps: {
          href: '/profile',
        },
        label: (
          <Trans i18nKey="Profile" ns="admin-layout">
            User profile
          </Trans>
        ),
        icon: <AccountCircleIcon />,
      },
      {
        key: 'logout',
        onClick: popupState.close,
        AnchorProps: {
          role: 'button',
          tabindex: '0',
          onClick: logout,
        },
        label: (
          <Trans i18nKey="Logout" ns="admin-layout">
            Logout
          </Trans>
        ),
        icon: <PowerOffIcon />,
      },
    ],
    [popupState.close, logout]
  );

  return (
    <Menu
      css={`
        [class^='MuiList-root'] {
          padding: 0;
        }
      `}
      {...bindMenu(popupState)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {items.map(({ key, ...item }, i) => (
        <Fragment key={key}>
          {!item.options && <OptionItem {...item} />}
          {item.options && <SubOptions {...item}>{item.options}</SubOptions>}
          {i < items.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Menu>
  );
};

Options.propTypes = {
  hasIssuerRole: PropTypes.bool,
};

export default Options;
