import React from 'react';
import styled from 'styled-components';
import {
  Toolbar as MuiToolbar,
  AppBar,
  Container,
  IconButton,
} from '@material-ui/core';
import useDeviceSize from '@vdx-platform/core/sizes/breakpoints/use_screen_size';
import BrandLogo from '@vdx-platform/core/shared/app/BrandLogo';
import MenuIconButton from '@vdx-platform/core/shared/app/menu/trigger';
import { A } from '@vdx/hookrouter';
import Profile from './profile';
// import TopNavigation from '../Navigation/Top';

const Toolbar = styled(MuiToolbar)`
  padding-left: 0.1rem;
  padding-right: 0.1rem;
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
`;

const Header = styled(({ className }) => {
  const { isSmall } = useDeviceSize();

  return (
    <AppBar className={className} color="white">
      <Container maxWidth={false}>
        <Toolbar>
          <LogoWrapper>
            {isSmall && (
              <MenuIconButton
                css={`
                  color: ${({ theme }) => theme.palette.primary.contrastText};
                `}
              />
            )}
            <BrandLogo component={A} href="/certificates" color="contrast" />
          </LogoWrapper>
          <Actions>
            {/* <TopNavigation /> */}

            <IconButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14.857 17.082C16.7202 16.8614 18.5509 16.4217 20.311 15.772C18.8204 14.1208 17.9967 11.9745 18 9.75V9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63215 5.88258 6 7.4087 6 9V9.75C6.00302 11.9746 5.17899 14.121 3.688 15.772C5.421 16.412 7.248 16.857 9.143 17.082M14.857 17.082C12.959 17.3071 11.041 17.3071 9.143 17.082M14.857 17.082C15.0011 17.5319 15.0369 18.0094 14.9616 18.4757C14.8862 18.942 14.7018 19.384 14.4234 19.7656C14.1449 20.1472 13.7803 20.4576 13.3592 20.6716C12.9381 20.8856 12.4724 20.9972 12 20.9972C11.5276 20.9972 11.0619 20.8856 10.6408 20.6716C10.2197 20.4576 9.85508 20.1472 9.57662 19.7656C9.29817 19.384 9.11377 18.942 9.03842 18.4757C8.96307 18.0094 8.9989 17.5319 9.143 17.082"
                  stroke="#454545"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
            <Profile />
          </Actions>
        </Toolbar>
      </Container>
    </AppBar>
  );
})``;

export default Header;
