import React, { Suspense } from 'react';
import { CircularProgress, Link } from '@material-ui/core';
import { Img as ReactImage } from 'react-image';
import styled from 'styled-components';

export const Img = styled(ReactImage)`
  ${p => p.theme.mixins.toolbar}
  object-fit: contain;
  padding: 1.5rem;
  text-align: center;
  width: auto;
`;

const BrandLogo = styled(
  ({ className, color, component = Link, ...AnchorProps }) => {
    const Anchor = component;

    return (
      <Anchor className={className} {...AnchorProps}>
        <Suspense fallback={<CircularProgress />}>
          <svg
            width="100"
            height="46"
            viewBox="0 0 100 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.62 13.2153H40.796L45.104 26.0969L49.624 13.2153H53.692L46.656 32.0432H43.284L36.62 13.2153Z"
              fill="black"
            />
            <path
              d="M53.076 25.6425C53.076 20.83 56.312 17.895 60.676 17.895C63.512 17.895 65.708 19.077 65.708 21.5536C65.708 24.9148 61.64 26.3661 57.28 26.4224C57.628 28.4648 58.832 29.5423 61.08 29.5423C62.5 29.5423 64.184 29.1403 65.468 28.1432L65.388 30.9414C64.048 31.9626 62.2561 32.4491 60.2521 32.4491C56.3441 32.4491 53.08 30.4308 53.08 25.6465L53.076 25.6425ZM62.28 21.6903C62.28 20.4802 61.2881 20.2631 60.5401 20.2631C58.6921 20.2631 57.3561 21.7145 57.1961 24.4604C59.8721 24.3278 62.28 23.5478 62.28 21.6903Z"
              fill="black"
            />
            <path
              d="M67.632 18.2972H71.352L71.62 22.0885C72.368 19.2098 73.84 17.8911 75.472 17.8911C76.116 17.8911 76.704 17.9997 77.052 18.1886L76.624 21.7106C76.168 21.413 75.632 21.3608 75.152 21.3608C72.636 21.3608 71.648 24.0505 71.648 26.7402V32.0391H67.636V18.2972H67.632Z"
              fill="black"
            />
            <path
              d="M77.48 25.9922C77.48 20.9104 80.288 17.895 85.48 17.895C87.568 17.895 89.788 18.3775 91.1 19.0248V32.043H87.436L87.22 28.1432C86.416 30.455 84.864 32.4451 82.244 32.4451C79.46 32.4451 77.48 30.2419 77.48 25.9922ZM87.112 23.3307V20.5606C86.712 20.4279 85.988 20.2912 85.32 20.2912C83.072 20.2912 81.6 21.7708 81.6 25.5098C81.6 27.9583 82.216 29.6227 83.74 29.6227C86.096 29.6227 87.112 25.6706 87.112 23.3307Z"
              fill="black"
            />
            <path
              d="M29.624 11.9447V23.9097C25.804 33.2291 19.26 36.7912 19.26 36.7912C9.72 29.667 8.896 23.9097 8.896 23.9097V11.8603C10.008 11.1527 11.196 10.5617 12.444 10.0913V22.9327C12.444 22.9327 12.98 26.72 19.26 31.4078C19.26 31.4078 23.568 29.0639 26.076 22.9327V10.1436C27.324 10.622 28.512 11.2291 29.624 11.9447Z"
              fill="black"
            />
            <path
              d="M23.516 9.35977V21.5579C21.964 26.6196 19.304 28.5535 19.304 28.5535C15.424 24.6858 15.088 21.5579 15.088 21.5579V9.31554C16.412 9.02205 17.788 8.87329 19.2 8.87329C20.684 8.87329 22.132 9.04215 23.52 9.35977H23.516Z"
              fill="#3366FF"
            />
          </svg>
        </Suspense>
      </Anchor>
    );
  }
)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
`;

BrandLogo.Img = Img;
export default BrandLogo;
