export default {
  palette: {
    variants: {
      primary: '#4764DB',
      secondary: '#fa7800',
      error: '#EC4F52',
      success: '#2BC86B',
      warning: '#F39D29',
      info: '#3FB6DD',
    },
    text: {
      default: '#757575',
      contrast: '#FFFFFF',
      neutral: '#454545',
      neutral400: '#CBCBCB',
      neutral500: '#B4B4B4',
      neutral600: '#999999',
      neutral800: '#454545',
      neutral700: '#7A7A7A',
    },
    background: {
      surface: '#FFFFFF',
      default: '#F5F7FA',
    },
  },
  brand: {
    url: process.env.REACT_APP_LANDING_URL,
    name: 'Vera',
    logo: {
      default: `${process.env.PUBLIC_URL}/images/logo.default.svg`,
      contrast: `${process.env.PUBLIC_URL}/images/logo.contrast.svg`,
    },
  },
};
