import axios from 'axios';
import { stringify } from 'query-string';
import { UNAUTHORIZED } from 'http-status-codes';
import errorHandler from './error_handler';
import redirectLoginHandler from './handlers/redirect_login_handler';
import networkErrorHandler from './handlers/network_error_handler';
import errorResponseApiHandler from './handlers/error_response_api_handler';
import blobApiErrorResponseHandler from './handlers/blob_error_response_api_handler';
import abortRequestHandler from './handlers/abort_handler';

const instance = axios.create();
const { CancelToken } = axios;

instance.defaults.paramsSerializer = query => stringify(query);
instance.defaults.baseURL = 'https://ai.veridical.tech/api/v1/';
instance.defaults.maxRedirects = 0;
instance.defaults.headers.common.Accept = 'application/json';

instance.interceptors.response.use(
  undefined,
  errorHandler(redirectLoginHandler, [UNAUTHORIZED])
);

instance.interceptors.response.use(
  undefined,
  errorHandler(abortRequestHandler)
);

instance.interceptors.response.use(
  undefined,
  errorHandler(networkErrorHandler)
);

instance.interceptors.response.use(
  undefined,
  errorHandler(blobApiErrorResponseHandler, status => status >= 400)
);

instance.interceptors.response.use(
  undefined,
  errorHandler(errorResponseApiHandler, status => status >= 400)
);

instance.interceptors.response.use(response => response.data);

export default instance;
export { CancelToken };
