/* eslint-disable babel/camelcase */
import httpClient from '@vdx-platform/core/api/client';

export default async (
  issuer,
  {
    fileId,
    title,
    placeholders,
    recipients,
    hasSignatureTrail,
    signers,
    summary,
    has_summary,
    ocr_file_id,
  },
  cancelToken
) => {
  const response = await httpClient.post(
    `/issuers/${issuer}/signables`,
    {
      file_id: fileId,
      title,
      engine: 'bitcoin',
      placeholders,
      recipients,
      has_signature_trail: hasSignatureTrail,
      signers: signers?.map(signer => ({ ...signer, has_summary })),
      summary,
      ocr_file_id,
    },
    {
      cancelToken,
    }
  );
  return response;
};
