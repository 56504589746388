import httpClient from '@vdx-platform/core/api/client';

export default async (issuer, uid, cancelToken) => {
  const response = await httpClient.post(
    `/issuers/${issuer}/signer_invitations/${uid}`,
    null,
    { cancelToken }
  );
  return response;
};
