import { action, createContextStore, actionOn } from 'easy-peasy';
import { set as setStoredUserType } from '../services/storage/user_type';
import { set as setStoredIssuer } from '../services/storage/issuer';

export const ISSUER_TYPE = 'ISSUER';
export const USER_TYPE = 'USER';

const DEFAULT_TYPE = ISSUER_TYPE;

const setActiveIssuer = issuers => {
  const veraIssue =
    issuers.find(issuerElement => issuerElement.issuer.name === 'Vera') || {};
  const issuer = {
    issuer: {
      id: 'vera',
      name: 'Vera',
      ...veraIssue?.issuer,
      theme: {
        brand: {
          url: 'https://vizidox.com',
          logo: {
            default: `${process.env.PUBLIC_URL}/images/logo.default.svg`,
            contrast: `${process.env.PUBLIC_URL}/images/logo.contrast.svg`,
          },
          name: 'Vera',
          favicon: `${process.env.PUBLIC_URL}/favicon.ico`,
        },
        palette: {
          text: {
            default: '#757575',
            contrast: '#FFFFFF',
            neutral500: '#B4B4B4',
            neutral600: '#999999',
            neutral700: '#7A7A7A',
            neutral800: '#454545',
          },
          action: {
            disabled: {
              default: '#FFFFFF48',
              contrast: '#FFFFFFE1',
            },
          },
          variants: {
            info: '#3FB6DD',
            error: '#EC4F52',
            primary: '#3366FF',
            success: '#2BC86B',
            warning: '#F39D29',
            secondary: '#fa7800',
          },
          background: {
            default: '#F5F7FA',
            surface: '#FFFFFF',
            primary100: '#F2F5FF',
          },
        },
      },
    },
    // eslint-disable-next-line babel/camelcase
    is_admin: true,
  };
  return {
    issuer: issuer.issuer,
    isAdmin: issuer.is_admin,
  };
};

export const userModel = ({ type = DEFAULT_TYPE, issuers }) => ({
  ...setActiveIssuer(issuers),
  type,

  setType: action((state, role) => {
    state.type = role;
    setStoredUserType(role);
  }),
  setIssuer: action((state, issuer) => {
    const selectedIssuer = issuers.find(
      item => item.issuer.name === issuer.name
    );
    state.issuer = selectedIssuer.issuer;
    state.isAdmin = selectedIssuer.is_admin;
    setStoredIssuer(selectedIssuer.issuer);
  }),
  onSetType: actionOn(
    actions => actions.setType,
    (state, { payload }) => {
      if (payload === USER_TYPE) {
        state.isAdmin = false;
      } else {
        state.isAdmin = issuers.find(
          item => item.issuer.id === state.issuer.id
        ).is_admin;
      }
    }
  ),
});

export default createContextStore(userModel, { name: 'UserType' });
