/* eslint-disable babel/camelcase */
import httpClient from '@vdx-platform/core/api/client';

export default async (
  issuer,
  file,
  params = { ignore_duplicate: false, hasSummarize: false, summarize: '' }
) => {
  const formData = new FormData();
  formData.append('ignore_duplicate', params.ignore_duplicate);
  formData.append('file', file);
  formData.append('summary', params.summarize);

  const response = await httpClient.post(`/issuers/${issuer}/files`, formData);
  return response;
};
