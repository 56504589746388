import React, { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import ErrorBoundary from 'react-error-boundary';
import { lazy } from '@vdx/loadable-component';
import { HelmetProvider } from 'react-helmet-async';
import { useRoutes, useRedirect } from '@vdx/hookrouter';
import { LinearProgress } from '@material-ui/core';
import useAuthorization from '@vdx-platform/core/auth/guards/use_authorization';
import authStore from '@vdx-platform/core/auth/store';
import i18n from '@vdx-platform/core/localization';
import Forbidden from '@vdx-platform/core/shared/app/forbidden';
import InternalServerError from '@vdx-platform/core/shared/app/internal_server';
import AppThemeProvider from '@vdx-platform/core/shared/theme';
import defaultTheme from '@vdx-platform/core/vizidox-brand-helpers/theme/default';
import userTypeStore from '@vdx-platform/core/user/store';
import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { lowerCase } from 'lodash';
import keycloak from '@vdx-platform/core/auth/keycloak';

import AdministrativeLayout from './Layout';

const Routes = lazy.lib(props => import(`./routes/${props.role}`));

const Router = ({ routes }) => useRoutes(routes);

const RouterContainer = () => {
  useRedirect('/', '/certificates', null, true);
  useRedirect('/signables/', '/certificates', null, true);
  useRedirect('/signables', '/certificates', null, true);

  const userType = userTypeStore.useStoreState(state => state.type);

  return (
    <Routes key={userType} role={lowerCase(userType)}>
      {({ default: routes }) => {
        return <Router routes={routes} />;
      }}
    </Routes>
  );
};

const Content = () => {
  const authError = authStore.useStoreState(state => state.error);
  const getProfile = authStore.useStoreActions(actions => actions.getProfile);
  const { isAuthenticated } = useAuthorization();
  const { initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) getProfile();
  }, [getProfile, initialized]);

  let Page = <LinearProgress />;
  if (isAuthenticated) {
    Page = (
      <AdministrativeLayout>
        <RouterContainer />
      </AdministrativeLayout>
    );
  } else if (authError?.code === 'forbidden_user') {
    Page = <Forbidden />;
  } else if (authError) {
    throw authError;
  }

  return <Suspense fallback={<LinearProgress />}>{Page}</Suspense>;
};

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <AppThemeProvider theme={defaultTheme}>
          <ErrorBoundary FallbackComponent={InternalServerError}>
            <authStore.Provider>
              <KeycloakProvider
                keycloak={keycloak}
                initConfig={{
                  onLoad: 'login-required',
                  promiseType: 'native',
                  checkLoginIframe: false,
                }}
              >
                <Content />
              </KeycloakProvider>
            </authStore.Provider>
          </ErrorBoundary>
        </AppThemeProvider>
      </HelmetProvider>
    </I18nextProvider>
  );
}

export default App;
