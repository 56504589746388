// if changed, reflect on the number of colors: issuer + n_recipients
export const MAX_RECIPIENTS = 6;

export const COLORS = [
  '#e69f00',
  '#56b3e9',
  '#009e74',
  '#f0e442',
  '#d55e00',
  '#0071b2',
  '#cc79a7',
];

export const DOCUMENT_TITLE_MAX_CHARS = 35;
export const ROLE_MAX_CHARS = 20;
export const FIRST_NAME_MAX_CHARS = 20;
export const LAST_NAME_MAX_CHARS = 20;
export const EMAIL_MAX_CHARS = 45;
